<template>
  <div class="flex w-full items-center gap-32">
    <slot />

    <div
      class="absolute bottom-[25%] top-[25%] flex w-full flex-col items-start justify-center gap-24 md:bottom-[10%] md:top-[10%]"
      :class="isFullWidth ? $style.containerFull : $style.containerSmall"
    >
      <div
        class="flex flex-col overflow-y-hidden md:h-full md:max-h-fit md:w-[50%]"
        :class="alignment"
      >
        <RichTextVueParser
          v-if="title"
          class="select-all"
          :class="[titleClass, 'mb-[3.3%] md:mb-[1.7%]']"
          :default-style="false"
          :rich-text="title"
        />

        <RichTextVueParser
          v-if="subtitle"
          :class="$style.subtitle"
          :default-style="false"
          :rich-text="subtitle"
        />

        <div
          v-if="link?.href && linkLabel"
          :class="['flex', CTAColours.wrapper ?? '']"
        >
          <RevButton
            class="!bg-local-cms-action-brand hover:!bg-local-cms-action-brand-hover"
            :class="CTAColours.button ?? ''"
            :size="CTASize"
            variant="primary"
            @click="onCTAClick"
          >
            <span :class="CTAColours.text">
              <span class="text-local-cms-brand select-text">
                {{ linkLabel }}
              </span>
            </span>
          </RevButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed, useCssModule } from 'vue'

import type { CarouselSlide } from '@backmarket/http-api/src/api-specs-content/models/carousel-content'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'

import { getCTAColors } from '../../utils/CTAColorCombinations'
import RichTextVueParser from '../RichText/RichTextVueParser.vue'

const props = withDefaults(
  defineProps<CarouselSlide & { isFullWidth: boolean }>(),
  {
    alignment: 'Left',
    fontType: 'Ivar',
    titleFontSize: 'L',
    ctaStyling: 'mid-tones_tangaroa',
    linkLabel: '',
  },
)

defineOptions({ inheritAttrs: false })

const router = useRouter()
const breakpoint = useDangerouslyComputedBreakpoint()
const classes = useCssModule()

const alignment = computed(() => {
  return props.alignment === 'Left'
    ? tw`items-start text-left mx-[4%] md:ml-[4%]`
    : tw`items-center text-center ml-[4%] mr-[4%] w-[92%] md:mx-auto`
})

const CTAColours = computed(() => {
  if (!props.link || !props.ctaStyling) return { text: '' }

  return getCTAColors(props.ctaStyling)
})

const CTASize = computed(() => {
  if (!props.isFullWidth && breakpoint.value === Breakpoint.MD) {
    return 'small'
  }

  return breakpoint.value === Breakpoint.MD ? 'small' : 'medium'
})

const titleClass = computed(() => {
  switch (props.titleFontSize) {
    case 'S':
      return props.fontType === 'Ivar'
        ? tw`cms-heading-small-serif ${classes.titleS}`
        : tw`cms-heading-small-sans ${classes.titleS}`

    case 'M':
      return props.fontType === 'Ivar'
        ? tw`cms-heading-medium-serif ${classes.titleM}`
        : tw`cms-heading-medium-sans ${classes.titleM}`

    case 'L':
      return props.fontType === 'Ivar'
        ? tw`cms-heading-large-serif ${classes.titleL}`
        : tw`cms-heading-large-sans ${classes.titleL}`

    default:
      return ''
  }
})

function onCTAClick(event: Event) {
  if (!props.link) return

  event.preventDefault()

  const resolved = router.resolve(props.link.href)
  if (resolved.matched.length) {
    router.push(resolved.fullPath)

    return
  }

  window.open(props.link.href, props.link.target)
}
</script>

<style lang="css" module>
.containerSmall {
  container-type: inline-size;

  @media (min-width: theme('screens.md')) {
    container-type: size;
  }
}

.containerFull {
  container-type: inline-size;
}

.subtitle {
  font-size: 4.5cqmin;
  line-height: 1;
  margin-bottom: 6.7%;

  @media (min-width: theme('screens.md')) {
    font-size: 1.5cqmax;
    margin-bottom: 3.4%;
  }
}

.titleS {
  font-size: 9cqmin;
  line-height: 1;
  font-weight: 600;

  @media (min-width: theme('screens.md')) {
    font-size: 3.4cqmax;
  }
}

.titleM {
  font-size: 10.5cqmin;
  line-height: 1;
  font-weight: 600;

  @media (min-width: theme('screens.md')) {
    font-size: 3.9cqmax;
  }
}

.titleL {
  font-size: 11cqmin;
  line-height: 1;
  font-weight: 600;

  @media (min-width: theme('screens.md')) {
    font-size: 4.5cqmax;
  }
}
</style>
