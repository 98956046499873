<template>
  <EnterViewportDetector class="m-auto block size-full">
    <template #default="{ inViewport }">
      <RevCarousel
        :alternative-button-label="i18n(translations.alternativeButtonLabel)"
        :alternative-next="i18n(translations.alternativeNext)"
        :alternative-previous="i18n(translations.alternativePrevious)"
        :autoplay="inViewport"
        class="relative m-auto block"
        :class="{
          'w-full lg:max-w-[168rem]': isFullWidth,
          'md:px-24 lg:max-w-[1120px] lg:px-0': !isFullWidth,
        }"
        current-index-id="cms-carousel"
        :tracking="trackingData"
      >
        <component
          :is="slide.tag"
          v-for="slide in carouselSlides"
          :key="slide.key"
          class="h-full w-full"
          :rel="slide.link?.rel"
          :target="slide.link?.target"
          :to="slide.link?.href"
          @click="slide.handleSlideClick"
        >
          <CarouselWrapper v-bind="slide" :is-full-width>
            <ImageRenderer
              :alt="slide.image?.alt"
              class="w-full"
              :height="slide.image?.height"
              :height-mobile="slide.image?.heightMobile"
              :loading="slide.isLoading"
              :media-max-width="MediaMaxWidth.SM"
              :src="slide.image?.src"
              :src-mobile="slide.image?.srcMobile"
              :width="slide.image?.width"
              :width-mobile="slide.image?.widthMobile"
            />
          </CarouselWrapper>
        </component>
      </RevCarousel>
    </template>
  </EnterViewportDetector>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import type { CarouselProps } from '@backmarket/http-api/src/api-specs-content/models/carousel-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import type { CarouselData } from '@backmarket/nuxt-module-tracking/types'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCarousel } from '@ds/components/Carousel'

import {
  Loading,
  MediaMaxWidth,
} from '@backmarket/nuxt-layer-cms/ImageRenderer.constants'
import ImageRenderer from '@backmarket/nuxt-layer-cms/ImageRenderer.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import EnterViewportDetector from '../../shared-components/EnterViewportDetector/EnterViewportDetector.vue'

import translations from './Carousel.translations'
import CarouselWrapper from './CarouselWrapper.vue'

const i18n = useI18n()
const route = useRoute()
const { trackCarouselClick } = useTracking()

const props = withDefaults(defineProps<CarouselProps & ContentBlockProps>(), {
  isFullWidth: true,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const trackSlideClick =
  (slide: CarouselProps['slides'][0], index: number) => () => {
    trackCarouselClick({
      ...(props.tracking || {}),
      creative: slide.image?.alt,
      name: String(route.name),
      position: `${String(route.name)}_${index + 1}`,
    })
  }

const carouselSlides = computed(() => {
  return props.slides.map((slide, index) => {
    const hasLink = !isEmpty(slide.link)

    return {
      ...slide,
      tag: hasLink ? RevButtonBase : 'div',
      key: slide.image?.src,
      target: slide.link?.target,
      isLoading: index === 0 ? Loading.Eager : Loading.Lazy,
      handleSlideClick: hasLink ? trackSlideClick(slide, index) : undefined,
    }
  })
})

const trackingData = computed<Omit<CarouselData, 'currentSlide'>>(() => {
  return {
    ...(props.tracking || {}),
    name: String(route.name),
    creatives: props.slides.map((slide) => ({ creative: slide.image?.alt })),
  }
})
</script>
